export const GET_FAVORITE_BOOKS = "GET_FAVORITE_BOOKS";
export const LIBRARY_BOOKS = "LIBRARY_BOOKS";
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const FAVORITE_THIS_BOOK = "FAVORITE_THIS_BOOK";
export const GET_FILTERED_BOOKS = "GET_FILTERED_BOOKS";
export const EMPTY_FILTERED_BOOKS = "EMPTY_FILTERED_BOOKS";
export const GET_CHAPTERS_TITLES = "GET_CHAPTERS_TITLES";
export const GET_ONE_CHAPTER = "GET_ONE_CHAPTER";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPLOAD_PROFILE_INFORMATIONS = "UPLOAD_PROFILE_INFORMATIONS";
export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const GET_BOOKMARKS = "GET_BOOKMARKS";
export const GET_BOOK = "GET_BOOK";
export const GET_SEARCHED_BOOKS = "GET_SEARCHED_BOOKS";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const CUSTOM_SUBSCRIPTION = "CUSTOM_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION="UPDATE_SUBSCRIPTION"
export const GET_RECENT_CHAPTERS ="GET_RECENT_CHAPTERS"
export const POST_REVIEW = "POST_REVIEW"
export const EMPTY_SEARCHED_BOOKS = "EMPTY_SEARCHED_BOOKS"
export const CHAPTER_BLOCK_CONFIG = "CHAPTER_BLOCK_CONFIG" 