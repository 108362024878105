import Footer from "../Components/Footer";

import Header from "../Components/Header";


export function UserAgreement(){
    return (<>
      <Header />
      <Footer />

    </>)
}