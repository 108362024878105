import React, { useEffect, useState } from 'react';
import '../css/style.css';
import { IoClose } from "react-icons/io5";
import { useLocation } from 'react-router-dom';


const MobileAdBanner = () => {
    const params = useLocation()
    const [isClose, setIsClose] = useState(false);
    const [currentPath, setCurrentPath] = useState("") 
    const [linkUrl, setLinkUrl] = useState("");
    const appInfo = {
    imageUrl:
      'https://play-lh.googleusercontent.com/-nieIqT_3t-HB9i-pwrSkoXCBwBSbzkNUz0ZSCOADsWa4tQL4pE7UHijIMqIZ5BLTw=w240-h480-rw',
    name: 'CSnovels',
    publisher: 'Cannedsplam, LLC',
    infoText: 'The #1 Webnovel App',
    linkButtonText: 'Download',
    linkUrl:
      'https://play.google.com/store/apps/details?id=com.csnovels.flutter',
  };

  useEffect(()=>{
    console.log("PPPPPAAAAARRRRRAAAAAMMMMMSSSS",params)
    if(currentPath !== params.key){
        setIsClose(false);
        setCurrentPath(params?.key)
    }
  },[params])
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
      setLinkUrl("https://play.google.com/store/apps/details?id=com.csnovels.flutter")
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setLinkUrl("https://apps.apple.com/us/app/csnovels/id6449099251")
    } else {
      setLinkUrl("https://apps.apple.com/us/app/csnovels/id6449099251")
    }
  }, []);

  return (
    <div
      position="top"
      
      className={isClose ? 'main-div-close' : 'main-div'}
    >
     <IoClose className="close-button"
     onClick={() => {
        setIsClose(true);
      }} />   
      <div className="inner-banner-div">
        <img src="https://play-lh.googleusercontent.com/-nieIqT_3t-HB9i-pwrSkoXCBwBSbzkNUz0ZSCOADsWa4tQL4pE7UHijIMqIZ5BLTw=w240-h480-rw" />
        <div className="info-div">
          <h1>CSnovels</h1>
          <p>The #1 Webnovel App</p>
        </div>
        <button className="button-91">
            <a href={linkUrl}>
            Get App
            </a> 
            </button>
      </div>
    </div>
  );
};

export default MobileAdBanner;
