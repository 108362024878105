
import React from 'react';


function AddComponent() {

    return (
        <div>
            <ins className='adsbygoogle'
                style={{ display: 'block' }}
                data-ad-client="ca-pub-5004354455774494"
                data-ad-slot="7885184093"
                data-ad-format='auto'
                data-full-width-responsive="true" />
        </div>
        //     <Modal show ={popup} onHide={() => closeModal()} className="Modal">
        // <Modal.Header class="modal-header">
        //         <Modal.Title class="modal-title">
        //           {/* {!modalcontent.amount ? "Choose Amount" : ""} */}
        //         </Modal.Title>
        //         <button
        //           type="button"
        //           className="close"
        //           data-dismiss="modal"
        //           onClick={closeModal}
        //         >
        //           &times;
        //         </button>
        //         </Modal.Header>
        //         <Modal.Body>
        //     <ins className='adsbygoogle'
        //       style={{ display: 'block' }}
        //       data-ad-client="ca-pub-5004354455774494"
        //       data-ad-slot="7885184093"
        //       data-ad-format='auto'
        //       data-full-width-responsive="true" />          
        //       </Modal.Body>
        // </Modal>
    );

}
export default AddComponent;